.landing {
    height:100%;
    width:100% !important;
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: hsl(220, 13%,18.5%);
}

.cart-splash { 
    height: 100%;
}

.checkout-splash-row {
    height: 100%;
}

.firstDiv {
    padding-top: 6em;
}

.cart {
    padding-top:4em;
    margin-left:auto;
    margin-right:auto;
    min-height: 46em;
}

.cart-header {
    text-align: left;
}

.checkout-button{
    margin-left:auto;
    margin-top:1em;
}

.footer, .company-info {
    background-color: hsl(220, 13%, 17%);
}