.calendar {
    color: #cfcfcf !important;
}
  
.flex-grow-1.overflow-auto.position-relative {
overflow-y: hidden !important; 
}
div.border-primary, div.border-top, div.border-end, div.border-bottom, div.border-start {
    border-color: hsl(220, 13%, 23%) !important;
    border-left-color: hsl(220, 13%, 23%) !important;
    border-top-color: hsl(220, 13%, 23%) !important;
    border-right-color: hsl(220, 13%, 23%) !important;
    border-bottom-color: hsl(220, 13%, 23%) !important;
}
.prediction, .sleep-time {
    border-radius: 16px;
    font-weight: 700;
    /* cursor: pointer; */
}
.prediction {
    background-image: linear-gradient(hsl(306, 96%, 53%), hsl(266, 100%, 66%)) !important;
}

.sleep-time {
    background-image: linear-gradient(rgb(18, 208, 250), rgb(19, 172, 206)) !important;
    color: black;
}
.time-label{
    /* margin-top: 20px */
    text-align: right;
    /* margin-top: 10px;  */
    margin-left: 48px;
}
div.timeslot {
    padding-top: 3px !important;
    height: 100%;
}
div.timeslot > * {
    font-size: 12px;
}
.progress-bar {
    background-image: linear-gradient(hsl(268, 95%, 52%), hsl(268, 80%, 52%)) !important;

}
.week-column {
    position: relative;
}
.week-column .current-day-line {
    width: calc(100% - 4px) !important;
    margin: 0 2px;
    height: 2px;
    background-color: orange;
    z-index: 999;
    position: absolute !important;
  }

.delete-sleep-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  background: none;
  border: none;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 0 6px;
  line-height: 1;
  border-radius: 50%;
  opacity: 0.7;
}

.delete-sleep-btn:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

.timeslot {
  position: relative;
  height: 100%;
}

.wake-time {
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
}
.hour-cell {
  height: 20px;
  position: relative;
  z-index: 1;
}
.work-hours {
  background-color: rgba(0, 120, 255, 0.1) !important;
}

.social-hours {
  background-color: rgba(255, 165, 0, 0.1) !important;
}

.sleep-hours {
  background-color: rgba(128, 0, 255, 0.1) !important;
}

.time-emoji {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  opacity: 0.5;
}

.sleep-time, .prediction {
  position: absolute;
  z-index: 2;
  border-radius: 16px;
  font-weight: 700;
  pointer-events: auto;
  opacity: 1;
}