.company-info {
    min-height:10em;
    padding-top: 2%;
    padding-bottom: 2%;
    border-top-color: #404040;
    border-top-width: 2px;
    border-top-style:solid;
}

.link-list-ul {
    list-style: none;
    padding-left:0;
}

.link-list-li {
    text-align: left;
}
.link-list-li > a {
    text-decoration: none;
    color: hsl(0, 0%, 60%);
}
.link-list-li > a, .link-list-li > a:visited {
    color: hsl(0, 0%, 60%);
}
.link-list-li > a:hover {
    color:hsl(0, 0%, 70%);
}

.link-list-name-header {
    text-align: left;
}

.company-info-name  {
    display:flex;
    align-items: center;
    justify-content: center;
}

.landing {
    height: 100%;
}

.content {
    padding-top:0em;
    margin-left:auto;
    margin-right:auto;
    min-height: 46em;
}

.content > * {
    text-align: left;
}