.progress-container {
  position: relative;
}

.progress {
  height: 25px;
  position: relative;
}

.progress-labels {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 5px;
  font-weight: bold;
}

.label-start,
.label-end {
  padding: 2px 4px;
}

.marker {
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: black;
}

.marker-container {
  position: relative;
  margin-top: 5px; /* Add space below the progress bar */
}

.marker-label {
  position: absolute;
  padding: 2px 4px;
  font-weight: bold;
  white-space: nowrap;
  transform: translateX(-50%);
}

.progress-and-reporting {
  max-width: 715px;
  margin-left: auto;
  margin-right: auto;
}
.wake-up-button {
  margin-top: 12px;
  font-weight: 800 !important;
  color: white !important;
  width: 100%;
  background-image: linear-gradient(56deg, rgb(69, 196, 249), rgb(125, 9, 255) 50.33%, rgb(255, 182, 11));
  background-attachment: scroll;
  background-origin: padding-box;
  background-position: 0 0;
  background-repeat: repeat;
  background-size: 140%;
  border: none;
  border-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.417) 0px 4px 4px 0px;
  transition: transform 0.16s ease-in-out;
}
.wake-up-button:disabled {
  background-image: linear-gradient(56deg, hsl(198, 80%, 62%), hsl(268, 80%, 52%) 50.33%, hsl(42, 80%, 52%));
  color: white !important;
  border:none;
}
.wake-up-button:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.268) 0px 12px 12px 0px;
  color: white;
  transform: translateY(1px);
}

.progress-and-reporting {
  position: relative;
  padding-top: 10px; /* Add padding to prevent overlap */
}

.info-tooltip {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  width: 400px;
  font-size: 18px !important;
  font-weight: 300 !important;
  background-color: #505050;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 125%; /* Adjust as necessary */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.info-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.n24-calendar-row {
  display: block;
}

@media (max-width: 768px) {
  .n24-calendar-row {
      display: none !important;
  }
}

.previous-wake-time {
  margin-top: 48px;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.previous-wake-time input[type="datetime-local"] {
  background-color: #2b2b2b;
  color: white;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 8px;
  flex: 1;
}

.previous-wake-time input[type="datetime-local"]:focus {
  outline: none;
  border-color: rgb(69, 196, 249);
}

.previous-wake-up-button {
  font-weight: 600 !important;
  color: white !important;
  background-color: #3d3d3d !important;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.417) 0px 4px 4px 0px;
  transition: transform 0.16s ease-in-out;
}

.previous-wake-up-button:hover {
  cursor: pointer;
  background-color: #585858 !important;
  box-shadow: rgba(0, 0, 0, 0.268) 0px 12px 12px 0px;
  transform: translateY(1px);
}

.donate-header {
  margin-top: 3em;
}

.donation-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 400px;
    margin: 1em auto 0 auto;
}

.donation-container .input-group {
    display: flex;
    width: 100%;

}

.donation-container .input-group-text {
    padding: 0.5rem;
    /* background-color: hsl(220, 13%, 13%)! ; */
    color: #fff;
    border: 1px solid #444;
    border-right: none;
    border-radius: 4px 0 0 4px;
}

.donation-input {
    flex: 1;
    border-left: none;
    border-radius: 0 4px 4px 0;
}

.donation-input, .donation-message {
    padding: 0.5rem;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: hsl(220, 13%, 13%) !important;
    color: #fff;
}

.donation-input:focus, .donation-message:focus {
    outline: none;
    border-color: #666;
}

.donation-message {
    resize: vertical;
}

.donate-button {
    background-color: rgb(18, 208, 250) !important; /* Teal */
    color: black;
    font-weight: 800;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transform: translateY(0);
    transition: all 0.2s ease;
    box-shadow: rgba(0, 0, 0, 0.417) 0px 4px 4px 0px;
  }

.donate-button:hover {
  background-color: rgb(41, 212, 250) !important; /* Darker teal for hover */
  transform: translateY(1px);
  box-shadow: rgba(0, 0, 0, 0.268) 0px 12px 12px 0px;
  color: black !important;
}

.donate-button:disabled {
  background-color: hsl(191, 70%, 53%) !important;
  cursor: not-allowed;
}
.donation-explanation {
  text-align: left;
}