.topbar {
    height:4em;
}

.topbar {
    background-color: hsl(220, 13%, 20%);
}

.topbar-ul {
    list-style: none;
    padding-left:0;
}

.topbar-li {
    float: right;
    text-align: right;
    margin-right:1em;
}
.topbar-link {
    color:#cfcfcf !important;
}
.top-bar-navbar {
    background-color: hsl(220, 13%, 20%);

}
.top-bar-navbar a {
    text-decoration: none!important;
    margin-left:1em;
}

.faked-link {
    text-decoration: none;
}
.nav-link.light,.nav-link:hover.light {
    color: rgba(0, 0, 0, 0.55);
}

.nav-link.dark,.nav-link:hover.dark {
    color: rgba(255, 255, 255, 0.55);
}
.navbar-brand > a {
    color:white;
}
.navbar-brand > a:hover {
    color:gray;
}

span.light>a,a.light {
    color: black;
}

span.dark>a,a.dark {
    color: white;
}
.logout-button {
    cursor: pointer;
} 
.email {
    margin-top:0.5em;
    color: #848484;
}