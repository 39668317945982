.App {
  text-align: center; 
  padding: 0 !important;
  background-color: hsl(220, 13%, 18%);
  color:#cfcfcf !important;
}
.form-select {
  background-color: hsl(220, 13%, 30%) !important;
  border-color: hsl(220, 13%, 20%) !important;
  color:#cfcfcf !important;
}
.form-select:focus,.form-select:active {
  outline: none !important;
  box-shadow: none !important;
  /* box-shadow: none; */
}
.contents {
  padding-right: 24px;
  padding-left: 24px;
  overflow-x: hidden;
}
.contents > .row {
  max-width: 1125px;
  margin-left:auto;
  margin-right: auto;
}
html {
  scrollbar-color: hsl(222, 13%, 30%) hsl(222, 12%, 26%);
}
::-webkit-scrollbar {
  width: 9px;
  height: 8px;
  background-color: hsl(222, 12%, 26%);
}

::-webkit-scrollbar-thumb {
  background: hsl(222, 13%, 30%);
  border-radius: 5px;
}
#root, .App, html, body, #app, #app>div {
  height: 100%;
  overflow-x: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-on-container-boyo {
  width:100%;
  padding-left:0px;
  padding-right:0px;
}
.input-group > .input-group-text {
  background-color: hsl(220, 13%, 13%);
  border-color: hsl(220,13%,30%);
  color: #cfcfcf;
}
.input-group > input {
  background-color: hsl(220, 13%, 13%);
  border-color: hsl(220,13%,30%);
  color: #cfcfcf;
}
.input-group > input:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: hsl(220,13%,30%);
  background-color: hsl(220, 13%, 13%);
  color: #cfcfcf;
}

h1 {
  font-size: 48px !important;
  font-weight: 800 !important;
  font-feature-settings: "liga" 0, "clig" 0 !important;
  color:hsl(0, 0%, 96%) !important;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
h2 {
  font-size: 40px !important;
  font-weight: 900 !important;
  font-feature-settings: "liga" 0, "clig" 0 !important;
  color:hsl(0, 0%, 96%) !important;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
h3 {
  font-size: 36px !important;
  font-weight: 800 !important;
  font-feature-settings: "liga" 0, "clig" 0 !important;
  color:hsl(0, 0%, 96%) !important;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
h4 {
  font-size: 20px !important;
  font-weight: 1000 !important;
  font-feature-settings: "liga" 0, "clig" 0 !important;
  color:hsl(0, 0%, 96%) !important;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
.header-container {
  margin-top: 50px !important;
  max-width: 450px !important;
  margin-bottom: 50px;
}
.subtitle {
  font-size: 20px !important;
  font-weight: 500 !important;
  font-feature-settings: "liga" 0, "clig" 0 !important;
  color:hsl(0, 0%, 96%) !important;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
.personas {
  margin-top: 24px !important;
  margin-bottom: 24px;
}
.persona {
  width: 150px;
  height: 110px;
  display: inline-block;
  border: 1px solid hsl(220, 13%, 30%) !important;
  border-radius: 14px;
  margin-right: 20px;
  padding-top:16px;
  font-size: 16px;
  font-weight: 500;
}
.persona > p:first-of-type {
  font-size: 32px;
  margin-bottom: 4px;
}
.jumbotron {
  border: 1px solid hsl(220, 13%, 30%) !important;
  border-radius: 14px;
  margin-right: 24px;
  margin-left: 24px;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.27) 0px 3px 10px 0px;
  overflow: hidden;
}
.jumbotron-content {
  padding: 48px 32px;
}
.jumbotron > .row > *:first-of-type {
  padding-left: 64px;
  padding-top: 64px;
  padding-bottom: 64px;
}
.jumbo-button-primary {
  background-color: hsl(190, 100%, 50%) !important;
  color: hsl(220, 13%, 18%) !important;
  font-weight: 800 !important;
  box-shadow: rgba(0, 0, 0, 0.417) 0px 4px 4px 0px !important;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
  margin-right: 12px;
}
.jumbo-button-primary:hover {
  box-shadow: rgba(0, 0, 0, 0.417) 0px 12px 12px 0px !important;
  background-color: hsl(190, 100%, 57%) !important;
  transform: translateY(1px);
}
.jumbo-button-secondary {
  background-color: hsl(220, 13%, 28%) !important;
}
.jumbo-button-secondary:hover {
  background-color: hsl(220, 13%, 32%) !important;
}
.features  {
  text-align: left !important; 
}
.features > h3 {
  background-image: linear-gradient(269deg, rgb(250, 18, 227) 1.83%, rgb(158, 84, 255) 53.68%, rgb(18, 208, 250) 106.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background: -webkit-linear-gradient(rgb(69, 196, 249), rgb(255, 182, 11));
  
  --webkit-background-clip: text;
  --webkit-text-fill-color: transparent; */
}
.one-app {
  color: hsl(42, 100%, 52%)
}
.testimonial > * {
  display: inline-block;
}
.testimonial > img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transform: translateY(15px);
}
.testimonal-desc {
  margin-left: 12px;
  width: calc(100% - 115px);
  /* margin-top: 4px; */
  /* padding-top: 24px; */
}
.testimonal-desc > p:first-child {
  font-weight: 800;
  /* padding-top: 12px; */
  margin-bottom: 4px !important;
}
.testimonal-desc > p {
  /* margin-bottom: 8px; */
  font-size: 14px;
}
.story {
  height: 660px;
  border-radius: 16px;
  background-color: #61dafb;
  position: relative;
}
.story > img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.story-header {
  padding: 30px;
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 200px;
  border-bottom-left-radius: 16px;
  border-top: 1px solid #282c34;
  border-bottom-right-radius: 16px;
  backdrop-filter: blur(15px);
  box-sizing: border-box;
  text-align: left;
  background-color: hsla(218, 12%, 13%, 0.249)
}
.story-header > p {
  color: white;
  font-weight: 600;
  margin-bottom: 6px;
}
.solutions-col {
  padding: 32px !important;
  padding-right: 48px !important;
  padding-top: 48px !important;
  position: relative;
  background-color: hsla(224, 13%, 17%, 0.46);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 100%;
}
.solution-bundle {
  width: 100%;
  border: solid 2px hsl(220, 13%, 22%);
  border-radius: 16px;
  padding-top:12px;
  margin-bottom: 24px;
}
.solution-bundle > p:not(:last-child) {
  border-bottom: solid 2px hsl(220, 13%, 22%);
  padding-bottom:12px;
}
.solution-bundle > p {
  padding-left: 12px;
  padding-right: 12px;
}
.feature-checklist {
  list-style-type: none;
  padding: 0px;
}
.feature-checklist .check {
  color:hsl(42, 100%, 52%);
  font-weight: 800;
}
.solutions-col > button {
  background-image: linear-gradient(56deg, rgb(69, 196, 249), rgb(125, 9, 255) 50.33%, rgb(255, 182, 11)) !important;
  background-attachment: scroll !important;
  background-origin: padding-box !important;
  width: 100%;
  background-position-y: 0% !important;
    background-position-x: 0% !important;
    background-repeat: repeat !important;
    background-size: auto !important;
    border-bottom-color: rgb(255, 255, 255) !important;
    border-bottom-style: none !important;
    border-bottom-width: 0px !important;
    border-image-outset: 0 !important;
    border-image-repeat: stretch !important;
    border-image-slice: 100% !important;
    border-image-source: none !important;
    border-image-width: 1 !important;
    border-left-color: rgb(255, 255, 255) !important;
    border-left-style: none !important;
    border-left-width: 0px !important;
    border-right-color: rgb(255, 255, 255) !important;
    border-right-style: none !important;
    border-right-width: 0px !important;
    border-top-color: rgb(255, 255, 255) !important;
    border-top-style: none !important;
    border-top-width: 0px !important;
    box-shadow: rgba(0, 0, 0, 0.417) 0px 4px 4px 0px !important;
    /* cursor: default !important; */
    --webkit-border-image: none !important;
    background-size: 140% !important;
    background-position: 0% 0% !important;
    transition: transform 0.16s ease-in-out !important;
    margin-top:12px;
}

.solutions-col > button:hover {
  cursor: pointer;

  box-shadow: rgba(0, 0, 0, 0.268) 0px 12px 12px 0px !important;
  transform:translateY(1px);
}
.blurb {
  border-radius: 16px;
  /* background-color: rgb(69, 196, 249); */
  height: 400px;
  padding: 24px 32px 24px 32px;
  text-align: left;
  /* background-attachment: scroll !important;
  background-origin: padding-box !important; */
}
.blurb > p {
  font-weight: 800;
  color:hsl(0, 0%, 96%) !important;
}
.blurb-row {
  margin-bottom: 24px;
}
.blurb-1 {
  background-image: linear-gradient(rgb(125, 9, 255), rgb(148, 55, 255)) !important;
}
.blurb-2 {
  background-image: linear-gradient(rgb(120, 214, 255), rgb(40, 160, 211)) !important;
}
.blurb-3 {
  background-image: linear-gradient(rgb(69, 196, 249), rgb(125, 9, 255) ) !important;
}
.blurb-4 {
  background-image: linear-gradient(rgb(125, 9, 255), rgb(97, 27, 178)) !important;
}
.blurb-5 {
  background-image: linear-gradient(hsl(255, 13%, 15%), hsl(255, 13%, 30%)) !important;
}
.blurb-6 {
  background-image: linear-gradient(rgb(125, 9, 255), rgb(255, 182, 11)) !important;
}
.blurb-7 {
  background-image: linear-gradient(hsl(255, 13%, 15%), hsl(258, 27%, 26%)) !important;
}

.splash-image-container {
  width: 100%;
}
.splash-image { 
  width: 50%;
  max-width: 500px;
}
.primary-action-button {
  display: inline-block;
  height: 100% !important;
  width: 228px;
  background-image: linear-gradient(#8990c4 , #404883) !important;
  background-color: #404883 !important;
  border-top-color: #404883 !important;
  border-right-color: #404883 !important;
  border-bottom-color: #404883 !important;
  border-left-color: #404883 !important;
  border-radius: 24px !important;
  font-weight: bold;
}
.primary-action-button:hover {
  background-image: linear-gradient(#989fd1 , #50599a) !important;
}
.discord-button {
  width: 48px;
  height: 48px;
}
.secondary-action-button {
  height: 100% !important;
  width: 228px;
}
.discord-button, .secondary-action-button {
  margin-left: 5px;
  border-color: #404883!important;
  background-color: #282c34 !important;
  padding: 0px !important;
  border-radius: 30px !important;
  /* font-weight: bold; */
}
.discord-button:hover, .secondary-action-button:hover {
  background-color: #404883 !important;
  border-color: #8990c4 !important;
}
.reassurance {
  font-size: 12px;
}
.download-row {
  padding-bottom: 3em;
  padding-left: 4em;
  padding-right: 4em;
}
.templative-demo-image {
  width: 80%;
  /* margin-top:1em; */
  box-shadow: 5px 5px 5px hsl(220, 13%, 13%);
}
.pitch-col {
  text-align: center;
  max-width: 715px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.pitch-col > ul {
  list-style: none;
}
.button-postscript {
  margin-top: 12px;
  color: gray;
}
.button-postscript > a, .button-postscript > a:visited {
  color: gray;
}
.button-postscript > a:hover {
  color:hsl(0, 0%, 60%);
}
.download-blurb {
  max-width: 640px !important;
  height: 390px !important;
  /* padding-left: 0.25em; */
  /* padding-right: 0.25em; */
  /* margin-left: auto; 
  margin-right: auto;  */
  text-align: left;
}
/* .download-blurb > * {
  al
} */
.secondary-action-buttons-row {
  height: 48px;
  display: inline-block;
  margin-bottom: 1em;
}
.secondary-action-buttons-row .btn {
  margin-bottom: 1em;
}

.vertical-divider {
  display: inline-block;
  margin-left:1em;
  margin-top:0.5em;
  height: 100%;
  width: 1px;
  background-color: hsl(0, 0%, 44%);
} 
.exportPathImage {
  display: inline-block;
  height: 100%;
  margin-left: 0.5em;
}

.headline {
  font-size: 35px;
  text-align: left;
  margin-right: 0.25em;
  margin-bottom: 0px;
  background: -webkit-linear-gradient(#e3e5f2, #8990c4);
  font-weight: bold;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.secondary-headline {
  font-size: 30px;
}
.highlighted-headline {
  padding: .25em 0;
  background: #FFC107;
  color: #282c34;
}
.headline-description {
  font-size: 22px;
}
.portrait {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin: 1em;
}
.bold {
  font-weight: bold;
}
ul.feature-list {
  list-style: none;
}
ul.feature-list > li:before {
  content: '✓';
  color: #8990c4;
  margin-right: 1em;
}
.centered-wrapper {
  /* margin-top: auto;
  margin-bottom: auto; */
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}

.youtube-column > * > iframe {
  width: 100% !important;
}
.main-action {
  width: 420px !important;
  height: 60px !important;
  font-size:24px !important;
  font-weight: bold !important;
  color: white !important;
  background-attachment: scroll !important;
  background-origin: padding-box !important;
  background-image: linear-gradient(56deg, rgb(69, 196, 249), rgb(125, 9, 255) 50.33%, rgb(255, 182, 11)) !important;
  background-position-y: 0% !important;
  background-position-x: 0% !important;
  background-repeat: repeat !important;
  background-size: auto !important;
  border-bottom-color: rgb(255, 255, 255) !important;
  border-bottom-style: none !important;
  border-bottom-width: 0px !important;
  border-image-outset: 0 !important;
  border-image-repeat: stretch !important;
  border-image-slice: 100% !important;
  border-image-source: none !important;
  border-image-width: 1 !important;
  border-left-color: rgb(255, 255, 255) !important;
  border-left-style: none !important;
  border-left-width: 0px !important;
  border-right-color: rgb(255, 255, 255) !important;
  border-right-style: none !important;
  border-right-width: 0px !important;
  border-top-color: rgb(255, 255, 255) !important;
  border-top-style: none !important;
  border-top-width: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.417) 0px 4px 4px 0px !important;
  /* cursor: default !important; */
  --webkit-border-image: none !important;
  background-size: 140% !important;
  background-position: 0% 0% !important;
  transition: background-position 1s ease-in-out, transform 0.16s ease-in-out !important;
}
.main-action:hover {
  cursor: pointer;
  background-position: 70% 0% !important;

  box-shadow: rgba(0, 0, 0, 0.268) 0px 12px 12px 0px !important;
  transform:translateY(1px);
}

.signup-pane {
  max-width: 715px;
  padding: 48px;
  background-color: hsl(220, 13%, 20%);
  border-radius: 16px;
  margin-left: auto;
  margin-right: auto;
}
.signup-button {
  width: 100%;
  color:hsl(0, 0%, 100%) !important;
  background-color: hsl(220, 13%, 23%) !important;
  background-image: linear-gradient(56deg, hsl(220, 13%, 23%), hsl(220, 13%, 23%) 50.33%, hsl(220, 13%, 23%)) !important;
  border-color: rgb(255, 255, 255) !important;
  border-style: none !important;
  border-width: 0px !important;
  background-attachment: scroll !important;
  background-origin: padding-box !important;
  transition: background-image 1s ease-in-out, box-shadow 1s ease-in-out, transform 0.16s ease-in-out !important;
}
.signup-button:enabled {
  
  background-image: linear-gradient(56deg, rgb(69, 196, 249), rgb(125, 9, 255) 50.33%, rgb(255, 182, 11)) !important;
  box-shadow: rgba(0, 0, 0, 0.417) 0px 4px 4px 0px !important;
  /* cursor: default !important; */
  --webkit-border-image: none !important;
  background-size: 140% !important;
  background-position: 0% 0% !important;
  
}
.signup-button:enabled:hover {
  transform: translateY(1px);
}
.missing-email {
  color: lightgray;
  font-size: 12px;
  font-style: italic;
}

@media (max-width: 768px) {
  h1 {
    font-size: 36px !important;
  }
  
  h2 {
    font-size: 32px !important;
  }
  
  h3 {
    font-size: 28px !important;
  }

  .main-action {
    width: 100% !important;
    max-width: 420px;
    font-size: 20px !important;
  }

  .download-row {
    padding-left: 1em;
    padding-right: 1em;
  }

  .persona {
    width: 120px;
    height: 90px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .jumbotron > .row > *:first-of-type {
    padding-left: 24px;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .solutions-col {
    padding: 24px !important;
    border-radius: 0 0 14px 14px;
  }

  .jumbotron .row {
    flex-direction: column;
  }

  .col-7, .col-5 {
    width: 100%;
  }

  .testimonial {
    margin-bottom: 0;
  }

  .solution-bundle {
    margin-bottom: 16px;
  }
}

@media (min-width: 769px) {
  .solutions-col {
    border-radius: 0 14px 14px 0;
  }
}

@media (max-width: 576px) {
  .contents {
    padding-right: 16px;
    padding-left: 16px;
  }

  h1 {
    font-size: 32px !important;
  }

  .personas {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .persona {
    width: calc(50% - 20px);
    margin: 10px;
  }
}

.calendar-row {
    display: block;
}

@media (max-width: 768px) {
    .calendar-row {
        display: none !important;
    }
    .persona {
      padding-top: 6px;
    }
}